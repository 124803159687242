@import "@root/03-base/colors";

$palette-default: (
    light:     #fff, // White
    dark:      #000,// Black
    transparent: transparent,
);

$dark-mode-colors: (
    background:  #000,
    text:        #fff,
    border:      #fff,
);

$palette: map-extend($palette-default, $palette-custom);

$semantic-colors-custom: (
    primary:             #000,
    primary-active:      #e7aa49,
    secondary:           #dd9375,
    secondary-active:    #ffcec6,
    text:                #202020,
    text-secondary:      #5f5f5f,
    success:             #46bf81,
    alert:               #b40a0a,
    disabled:            #767676,
    element-background:  #d8d8d8,
    global-background:   #f3f3f3,
    organism-background: #595959,
    border:              #d8d8d8,
    highlight:           #db7318
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
$palette: map-extend($palette-default, $palette-custom);