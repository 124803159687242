@import '@root/05-components/social-share';

$social-share-icon-usesvg: false;
$social-share-icons-background: transparent;

@mixin apac-components-social-share {
    .c-social-share__link {
        @each $provider in $social-share-list {
            &.m-#{$provider} {
                @include svg-icon(#{$provider}, $social-share-icon-color, $usesvg: $social-share-icon-usesvg);
                
                background-color: $social-share-icons-background;
                mask: none;

                &:hover,
                &:focus {
                    @include svg-icon(#{$provider}, $social-share-icon-hover-color, 100%, left, no-repeat, $color-change: true, $usesvg: $social-share-icon-usesvg);
                }

                &:active {
                    @include svg-icon(#{$provider}, $social-share-icon-active-color, $color-change: true, $usesvg: $social-share-icon-usesvg);
                }
            }
        }
    }
}
